import React, { memo, useState } from 'react'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { useCurrentOrganization } from '@components/App'
import { momentFormats } from '@services/utils/moment'
import LinkTabNavigator from '@components/display/LinkTabNavigator'
import { Navigate, Route, Routes } from 'react-router-dom'
import { usePatientMARApprovals } from '../../../features/Patients/patientHooks'

import MarApprovalDialog from './MAR/MARApproval/MarApprovalDialog'
import DateNavigator from '../../display/DateNavigator/DateNavigator'
import useDateNavigatorUtils from '../../display/DateNavigator/dateNavigatorUtils'
import MAR from './MAR'
import TAR from './TAR'
import Vitals from './Vitals'

function Title({ title }) {
  return (
    <div className="flex flex-row justify-content-between align-items-center">
      <span className="text-lg">
        <i className="pi pi-list mr-2" />
        {title}
      </span>
    </div>
  )
}

const AdherenceDashboard = memo(({ patient, title, maxDataViewHeight }) => {
  const [approvalDialogVisible, setApprovalDialogVisible] = useState(false)
  const currentOrganization = useCurrentOrganization()
  const showTar = currentOrganization.treatmentsEnabled

  const {
    onPrevious, onNext, onGranularityChange, dateRange, setDateRange,
    granularity, startDate, endDate, totalDays,
  } = useDateNavigatorUtils(patient.timezone)

  const {
    data: marApprovals,
    isLoading: marApprovalsLoading,
  } = usePatientMARApprovals({
    patientId: patient.id,
    dayMin: startDate.format(momentFormats.date),
    dayMax: endDate.format(momentFormats.date),
  })

  const tabs = [
    {
      label: 'MAR',
      linkTo: 'MAR',
    },
  ]

  if (showTar) {
    tabs.push({
      label: 'TAR',
      linkTo: 'TAR',
    })
  }

  tabs.push({
    label: 'Vitals',
    linkTo: 'vitals',
  })

  return (
    <Card className="h-full adherence-dashboard">
      <MarApprovalDialog
        visible={approvalDialogVisible}
        onHide={() => setApprovalDialogVisible(false)}
        patientId={patient.id}
        currentStart={startDate}
        currentEnd={endDate}
        existingMarApprovals={marApprovals}
      />

      <div className="flex flex-column text-base text-900">
        <Title title={title} />
        <Divider className="mb-0 mt-3" />
        <LinkTabNavigator tabs={tabs} />
        <div className="h-1rem" />
        <DateNavigator
          dateRange={dateRange}
          onPrevious={onPrevious}
          onNext={onNext}
          granularity={granularity}
          setDateRange={setDateRange}
          marApprovals={marApprovals}
          onGranularityChange={onGranularityChange}
        />
        <div className="h-1rem" />
        <Routes>
          <Route path="*">
            <Route
              index
              element={(
                <Navigate
                  to="MAR"
                  replace
                />
            )}
            />
            <Route
              path="MAR/*"
              element={(
                <MAR
                  patient={patient}
                  startDate={startDate}
                  endDate={endDate}
                  dateRange={dateRange}
                  totalDays={totalDays}
                  granularity={granularity}
                  maxDataViewHeight={maxDataViewHeight}
                  marApprovals={marApprovals}
                  marApprovalsLoading={marApprovalsLoading}
                />
              )}
            />
            {
              showTar && (
                <Route
                  path="TAR/*"
                  element={(
                    <TAR
                      patient={patient}
                      startDate={startDate}
                      endDate={endDate}
                      totalDays={totalDays}
                      granularity={granularity}
                      maxDataViewHeight={maxDataViewHeight}
                    />
                  )}
                />
              )
            }
            <Route
              path="vitals"
              element={(
                <Vitals
                  patient={patient}
                  startDate={startDate}
                  endDate={endDate}
                  granularity={granularity}
                />
              )}
            />
          </Route>
        </Routes>
      </div>
    </Card>
  )
})

AdherenceDashboard.displayName = 'AdherenceDashboard'

export default AdherenceDashboard
